import React from 'react'
import StyledHome from './styled'
import { githubIcon, linkedinIcon, profilePic} from '../../assets'

import PageTitle from '../../components/page-title/'

const Home: React.FunctionComponent = () => {
  return (
    <StyledHome >
      <div className='home-my-container'>
        <img src={profilePic} className='home-profile-pic' />
        <div className='home-social-container'>
          <a href='https://github.com/edu-magno' target="_blank" rel='noreferrer'><img src={githubIcon} className='home-social-links' /></a>
          <a href='https://www.linkedin.com/in/edu-magno/' target="_blank" rel='noreferrer'><img src={linkedinIcon} className='home-social-links' /></a>
        </div>
      </div>

      <div className='home-about-me-container'>
        <PageTitle titleName='Sobre mim' topMargin={0} bottomMargin={0}/>
        <p className='home-about-me-text'>Olá, sou estudante  de  desenvolvimento  de
        software  naKenzie Academy Brasil. Também cursei 5 semestres de design,
        mas resolvi focar na carreira de programador por ter me empolgado com as disciplinas de desenvolvimento na faculdade.
        </p>
        <p className='home-about-me-text'>
        Na Kenzie, já tive a oportunidade de trabalhar usando HTML, CSS, JavaScript, React, Redux, Git e Scrum.
        Atualmente estou focando nos estudos de backend (Python, com Flask, e futuramente Django) com o objetivo de ser um dev fullstack.
        </p>
        <p className='home-about-me-text'>
        Gosto de resolver problemas, desde problemas mecânicos, passando por hardware e claro, de desenvolvimento de software. Sei trabalhar bem em equipe, tenho boa comunicação interpessoal e paciência.
        </p>
      </div>
    </StyledHome>
  )
}

export default Home