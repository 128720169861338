import styled from 'styled-components'

const StyledNavigationButton = styled.button`
  margin-right: 0.625rem;
  border:none;
  background-color: transparent;
  cursor: pointer;
  outline-style: none;
  img {
      width: 100%;
  }
`

export default StyledNavigationButton