import React from 'react'
import StyledNavigationButton from './styled'
import navButton from '../../assets/navigationButton.svg'

interface NavButtonProps {
    handleClick? : () => void;
}

const NavigationButton = ({ handleClick} : NavButtonProps) => {
    return(
        <StyledNavigationButton onClick={handleClick}>
            <img src={navButton}/>
        </StyledNavigationButton>
    )
}

export default NavigationButton