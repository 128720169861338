import React from 'react'
import StyledProjectContainer from './styled'
import Button from '../button'

interface ProjectCProps {
  title: string,
  image: string,
  imageAlt: string,
  acessLink: string,
  repositoryLink: string,

}

const ProjectContainer: React.FunctionComponent<ProjectCProps> = ({ title, image, imageAlt, acessLink, repositoryLink }: ProjectCProps) => {
  return (
    <StyledProjectContainer>
      <h3 className='project-title'>{title}</h3>
      <img src={image} className='project-image' alt={imageAlt}/>
      <div className='project-links'>
        <a href={acessLink} className='project-link'>
          <div>
            <Button color='#0B1737' colorHover='#050C1E' colorActive='#D2442D' buttonName='Acessar' width='140px' height='40px' />
          </div>
        </a>
        <a href={repositoryLink} className='project-link'>
          <div >
            <Button color='#0B1737' colorHover='#050C1E' colorActive='#D2442D' buttonName='Repositório' width='140px' height='40px' />
          </div>
        </a>
      </div>
    </StyledProjectContainer>
  )
}

export default ProjectContainer