import React from 'react';
import Header from './components/header';
import HeaderMobile from './components/header-mobile';
import Footer from './components/styled-footer';
import Contact from './pages/contact';
import Home from './pages/home'
import Projects from './pages/projects';

import Services from './pages/services-available'
import Skills from './pages/skills'
import StyledApp from './styled-app'
import { useWindowSize } from './utils/use-window-size'

const App: React.FunctionComponent = () => {
  const width = useWindowSize()[0]


  return (
    <StyledApp >
      {width !== undefined ? width > 900 ? <Header /> : <HeaderMobile /> : ''}
      <Home />
      <Services />
      <Skills />
      <Projects />
      <Contact />
      <Footer>
        <span> Desenvolvido por Eduardo Magno.</span>
      </Footer>
    </StyledApp>
  );
}

export default App
