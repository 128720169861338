import styled from 'styled-components'


const StyledHeader = styled.header`
position:fixed;
width: 100%;
height:10vh;
box-sizing: border-box;
border-bottom: 2px solid #D2442D;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0 10%;
color: #0B1737;
background-color: #FCFCFC;
z-index:1;

.header-title {
  font-size: 45px;
  margin: 0 1.25rem;
  font-family: Quantico;
}

.header-title span {
  font-family: Quantico;
  color: #D2442D;
}

.header-scroll {
  font-size: 25px;
  text-decoration: none;
  margin: 0 1.25rem;
  color: #0B1737;
  cursor: pointer;
}

 @media (max-width: 1368px) {
   .header-scroll {
     font-size: 20px;
     margin: 0 0.625rem;
   }
   .header-title {
  font-size: 40px;
  }
 }



 @media (max-width: 1150px) {
   padding: 0 6%;

  .header-title {
  font-size: 35px;
  }
}
`

export default StyledHeader