import React, { useState, useEffect } from 'react'
import StyledProjects from './styled'

import ProjectContainer from '../../components/project-container'
import PageTitle from '../../components/page-title'
import axios from 'axios'

const Projects: React.FunctionComponent = () => {

  interface projectProps {
    title:string,
    url:string,
    image_link: string,
    repository_link: string
  }

  const [projects, setProjects] = useState([])

  useEffect(() => {
    axios.get('https://portapiv1.herokuapp.com/projects')
    .then(res => setProjects(res.data))
  }, [])



  return (
    <StyledProjects>
      <PageTitle
        topMargin='72px'
        bottomMargin='40px'
        titleName='Projetos'
        subTitle='Projetos que desenvolvi e participei durante a minha jornada até agora' />
      <div className='projects-container'>

        {projects.map((project: projectProps, index: number) => <ProjectContainer
          key={index}
          title={project.title}
          image={project.image_link}
          imageAlt={project.title}
          acessLink={project.url}
          repositoryLink={project.repository_link}/>)}

      </div>
    </StyledProjects >
  )
}

export default Projects