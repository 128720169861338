import styled from 'styled-components'

const StyledProjects = styled.section`
  margin-bottom: 15vh;
  .projects-container {
    display: flex;
    justify-content: center;
    margin: 1.25rem;
  }


@media (max-width: 1024px) {
  .projects-container{
  flex-direction: column;
  align-items: center;
  }
}
`

export default StyledProjects