import styled from 'styled-components'


const StyledServices = styled.section`
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  margin-top: 15vh;
  margin-bottom: 15vh;
  .services-options {

    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }




`

export default StyledServices