import styled from 'styled-components'

const StyledContact = styled.section`
  width: 100%;

  .contact-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;


  .contact-or{
    margin: 2.50rem;
    font-size: 36px;
    font-weight: bold;
  }

  .contact-social-links {
    width: 30%;
    display: flex;
    justify-content: space-evenly;
  }
  }
  @media (max-width: 768px) {

    .contact-container {
      flex-direction: column;
    .contact-social-links {
      width: 80%;
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
  }

`

export default StyledContact