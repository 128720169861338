import React, { ChangeEvent, FormEvent, useState } from 'react'
import { githubIcon, linkedinIcon } from '../../assets'
import PageTitle from '../../components/page-title'
import Form from '../../components/form'
import StyledContact from './styled'
import axios from 'axios'

const Contact: React.FunctionComponent = () => {


  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>()
  const [subject, setSubject] = useState<string>('')
  const [message, setMessage] = useState<string>('')


  const onFinish = (event: FormEvent) => {
    event.preventDefault()
    axios.post('https://portapiv1.herokuapp.com/send-email', {
      "subject": subject,
      "content": `
        Olá meu nome é ${name}, email: ${email}

        E essa é a minha mensagem do contato
        ${message}
      `
    }).then(() => console.log('mensagem enviada'))
      .catch(err => console.log(err))
  }

  const handleName = (event: FormEvent) => {
    const target = event.target as HTMLTextAreaElement;
    setName(target.value)
  }

  const handleEmail = (event: ChangeEvent) => {
    const target = event.target as HTMLTextAreaElement;
    setEmail(target.value)
  }

  const handleSubject = (event: ChangeEvent) => {
    const target = event.target as HTMLTextAreaElement;
    setSubject(target.value)
  }

  const handleMessage = (event: ChangeEvent) => {
    const target = event.target as HTMLTextAreaElement;

    setMessage(target.value)
  }

  return (
    <StyledContact>
      <PageTitle
        topMargin='72px'
        bottomMargin='40px'
        titleName='Contato'
        subTitle='Para entrar em contato comigo, preencha o formulário ou acesse minhas redes sociais!' />

      <div className='contact-container'>
        <Form onFinish={onFinish} handleName={handleName} handleEmail={handleEmail} handleMessage={handleMessage} handleSubject={handleSubject} />
        <div className='contact-or'>Ou</div>
        <div className='contact-social-links'>
          <a href='https://github.com/edu-magno'><img src={githubIcon} alt='Github-link' /></a>
          <a href='https://www.linkedin.com/in/edu-magno/'><img src={linkedinIcon} alt='Linkedin link' /></a>
        </div>
      </div>

    </StyledContact>
  )
}

export default Contact