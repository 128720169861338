import styled from 'styled-components'


const Input = styled.input`
  border: 2px solid #D2442D;
  border-radius: 5px;
  height: 40px;
  width:100%;
  background-color: #fff;
  outline-style: none;
  font-size: 20px;
  margin: 1.25rem;
`

export default Input
