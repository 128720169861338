import React from 'react'
import StyledSkill from './styled'

interface SkillProps {
  skillTitle: string,
  image: string,
  imageAlt: string
}


const Skill: React.FunctionComponent<SkillProps> = ({ skillTitle, image, imageAlt }: SkillProps) => {
  return (
    <StyledSkill>
      <span className='skill-title'>{skillTitle}</span>
      <img src={image} alt={imageAlt} className='skill-image'/>
    </StyledSkill>
  )
}

export default Skill