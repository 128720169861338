import styled from 'styled-components'

const StyledHeaderMobile = styled.header`
  box-sizing: border-box;
  position:fixed;
  width:100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem;
  border-bottom: 2px solid #D2442D;
  background-color: #FCFCFC;
`

export default StyledHeaderMobile