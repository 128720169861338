import React, { useState } from 'react'
import NavigationButton from '../navigation-button'
import NavigationMobile from '../navigation-mobile'
import Title from '../title'
import StyledHeaderMobile from './styled'

const HeaderMobile: React.FunctionComponent = () => {

    const [navMenu, setNavMenu] = useState(false)

    const handleClick = () => {

        navMenu ? setNavMenu(false) : setNavMenu(true)
    }
    return(
        <StyledHeaderMobile>
            <Title fontSize='32px'/>
            <NavigationButton handleClick={handleClick} />
            {navMenu && <NavigationMobile handleClick={handleClick} />}
        </StyledHeaderMobile>
    )
}

export default HeaderMobile