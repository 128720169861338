/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-var-requires */
export const htmlIcon = require('./skills/icon-html5.svg')
export const cssIcon = require('./skills/icon-css3.svg')
export const javascriptIcon = require('./skills/icon-javascript.svg')
export const reactIcon = require('./skills/icon-react.svg')
export const typescriptIcon = require('./skills/icon-typescript.svg')
export const uiUxIcon = require('./skills/designer-de-web.svg')
export const profilePic = require('./profile-pic.jpg')
export const psyHealthImg = require('./projects/psyhealth.png')
export const helloHeduxImg = require('./projects/hello-redux.png')
export const userApi = require('./projects/user-api-home.png')
export const githubLinkIcon = require('./github-link.svg')
export const externalLinkIcon = require('./external-link.svg')
export const githubIcon = require('./social/icon-github.svg')
export const linkedinIcon = require('./social/icon-linkedin.svg')
export const apiIcon = require('./api.svg')
export const webDevelopIcon = require('./icon-web-dev.svg')
export const interfaceIcon = require('./icon-interface.svg')