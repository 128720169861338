import React, { ChangeEvent, FormEvent } from 'react'
import Button from '../button'
import Input from '../styled-input'
import TextArea from '../styled-text-area'
import StyledForm from './styled'


interface FormProps {
  onFinish: (event: FormEvent<Element>) => void,
  handleName: (event: ChangeEvent<Element>) => void,
  handleEmail: (event: ChangeEvent<Element>) => void,
  handleSubject: (event: ChangeEvent<Element>) => void,
  handleMessage: (event: ChangeEvent<Element>) => void
}

const Form: React.FunctionComponent<FormProps> = ({ onFinish, handleName, handleEmail, handleMessage, handleSubject }:FormProps) => {


  return (
    <StyledForm onSubmit={onFinish}>
      <Input placeholder='Nome' onChange={handleName} className='contact-input' />
      <Input placeholder='Email' onChange={handleEmail} className='contact-input' />
      <Input placeholder='Assunto' onChange={handleSubject} className='contact-input' />
      <TextArea rows={10} className='contact-input' onChange={handleMessage}/>
      <Button color='#0B1737' colorHover='#050C1E' colorActive='#D2442D' buttonName='Enviar' width='160px' height='40px' />
    </StyledForm>
  )
}

export default Form