import styled from 'styled-components'

const StyledSkills = styled.section`
  margin-bottom: 15vh;

  display: flex;
  flex-direction: column;
  align-items: center;



  .skills-container {
    width: 60%;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;

  }

`

export default StyledSkills