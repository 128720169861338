import styled from 'styled-components'

const StyledHome = styled.section`
display:flex;
justify-content: space-evenly;
align-items: center;
box-sizing: border-box;
margin-top: 30vh;
padding-left: 5%;
padding-right: 5%;


.home-profile-pic {
  width: 363px;
  height: 363px;
  border: 2px solid #D2442D;
  border-radius: 5px;
}
.home-social-container {
    width: 100%;
    margin-top: 1.25rem;
    display: flex;
    justify-content: space-around;
.home-social-links {
      width: 80px;
      height: 80px;

  }
}

.home-about-me-container {
  width: 50%;
  .home-about-me-text {
    font-size: 22px;
    margin-top: 1.25rem;
  }

}

@media (max-width: 768px) {
  flex-direction: column;
  align-items: center;
  margin-top: 15vh;

  .home-profile-pic {
    margin-top: 1.25rem;
    width: 200px;
    height: 200px;
    border: 2px solid #D2442D;
    border-radius: 5px;
  }

  .home-my-container {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .home-social-container {
    .home-social-links {
      width: 60px;
      height: 60px;
    }

  }


  .home-about-me-container {
    margin-top: 1.25rem;
    width: 100%;
    .home-about-me-text {

      font-size: 18px;
    }
  }
}


`

export default StyledHome