import styled from 'styled-components'
import {PageTitleProps} from './index'

const PageTitle = styled.div<Pick<PageTitleProps, "topMargin" | "bottomMargin"  >>`
  display: flex;
  flex-direction: column;
  align-items: center;
  .page-title{
    font-size: 48px;
    font-weight: bold;
    margin-top: ${props => props.topMargin};
    margin-bottom : ${props => props.bottomMargin};
  }

  .page-sub-title{
    font-size: 20px;
    margin-bottom: 2.50rem;
  }

  @media (max-width: 768px) {
    .page-title {
      font-size: 32px;
    }

    .page-sub-title {
      text-align: center;
      margin: 0 1.25rem 2.50rem 1.25rem ;
      font-size: 16px;
    }
  }
`

export default PageTitle
