import styled from 'styled-components'

const StyledForm = styled.form`
margin: 1.25rem;
display:flex;
flex-direction: column;
align-items: center;
width: 30%;

.contact-input{
  width:100%;
}

@media (max-width: 768px) {
  width: 80%;
}

`

export default StyledForm