import React from 'react'
import { Link } from 'react-router-dom'
import Title from '../title'
import StyledNavigationMobile from './styled'
import cancelIcon from '../../assets/cancel.svg'

interface NavMobileProps {
    handleClick?: () => void;
}

const NavigationMobile = ({ handleClick } : NavMobileProps) => {
    return(
       <StyledNavigationMobile>
           <div className='nav-back' onClick={handleClick}><img src={cancelIcon}/></div>
           <Title fontSize='36px'/>
           <div className='link-nav-container'>
           <Link to='/' className='link-nav-mobile'>Inicio</Link>
           <Link to='/serviços' className='link-nav-mobile'>Serviços</Link>
           <Link to='/habilidades' className='link-nav-mobile'>Habilidades</Link>
           <Link to='/projetos' className='link-nav-mobile'>Projetos</Link>
           <Link to='/contato' className='link-nav-mobile'>Contato</Link>
           </div>

       </StyledNavigationMobile>
    )
}

export default NavigationMobile