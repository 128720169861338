import styled from 'styled-components'

const StyledSkill = styled.div`
  color:#D2442D;
  text-align: center;
  margin: 2.50rem;
  display:flex;
  flex-direction:column;
  align-items: center;
  font-size: 25px;

.skill-title {
  margin-bottom:0.625rem;
}

@media (max-width: 1000px) {
  margin: 1.25rem;
.skill-image {
  width:100px;
}

}
`

export default StyledSkill