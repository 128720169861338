import React from 'react'
import StyledSkills from './styled'
import { htmlIcon, cssIcon, javascriptIcon, reactIcon, typescriptIcon, uiUxIcon } from '../../assets/index'
import PageTitle from '../../components/page-title'
import Skill from '../../components/skill'



const Skills: React.FunctionComponent = () => {
  return (
    <StyledSkills>
      <PageTitle
        topMargin='72px'
        bottomMargin='40px'
        titleName='Habilidades'
        subTitle='Essas são as tecnologias que venho aplicando na prática nos meus estudos e projetos pessoais.' />
      <div className='skills-container'>

        <Skill
          skillTitle='HTML'
          image={htmlIcon}
          imageAlt='Html icon' />
        <Skill
          skillTitle='CSS'
          image={cssIcon}
          imageAlt='css icon' />
        <Skill
          skillTitle='UI/UX'
          image={uiUxIcon}
          imageAlt='ui/ux icon' />
        <Skill
          skillTitle='Javascript'
          image={javascriptIcon}
          imageAlt='javascript icon' />
        <Skill
          skillTitle='React.js'
          image={reactIcon}
          imageAlt='react icon' />
        <Skill
          skillTitle='Typescript'
          image={typescriptIcon}
          imageAlt='typescript icon' />

      </div>
    </StyledSkills>
  )
}

export default Skills