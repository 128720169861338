import React from 'react'
import Title from '../title'
import StyledHeader from './styled'
import { useWindowSize } from '../../utils/use-window-size'

const Header: React.FunctionComponent = () => {
  const height = useWindowSize()[1]

  const scrollToHome = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const scrollToServices = () => {
    window.scrollTo({ top: height - ( height / 3) , behavior: 'smooth' })
  }

  const scrollToSkills = () => {
    window.scrollTo({ top: (height * 2) - ( height / 1.8) , behavior: 'smooth' })
  }

  const scrollToProjects = () => {
    window.scrollTo({ top: (height * 2.3), behavior: 'smooth' })
  }

  const scrollToContact = () => {
    window.scrollTo({ top: height * 3.2 , behavior: 'smooth' })
  }

  return (
    <StyledHeader>
      <Title />
      <nav className='header-nav-bar'>
        <span onClick={scrollToHome} className='header-scroll'>Inicio</span>
        <span onClick={scrollToServices} className='header-scroll'>Serviços</span>
        <span onClick={scrollToSkills} className='header-scroll'>Habilidades</span>
        <span onClick={scrollToProjects} className='header-scroll'>Projetos</span>
        <span onClick={scrollToContact} className='header-scroll'>Contato</span>

      </nav>
    </StyledHeader>
  )
}

export default Header