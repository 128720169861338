import React from 'react'
import StyledServices from './styled'

import { apiIcon, webDevelopIcon, interfaceIcon } from '../../assets'
import PageTitle from '../../components/page-title'
import ServiceOption from '../../components/service-option'

const Services: React.FunctionComponent = () => {
  return (
    <StyledServices>
      <PageTitle
        topMargin='72px'
        bottomMargin='40px'
        titleName='Serviços disponíveis'
        subTitle='Esses são os serviços que mais tenho me especializado no ultimo ano, tentando a cada dia sendo melhor e mais preparado no assunto.' />
      <div className='services-options'>
        <ServiceOption
          serviceTitle='Desenvolvimento de sites'
          serviceText='Desenvolvimento de sites para o seu negócio.'
          image={webDevelopIcon}
          imageAlt='Web developer icon' />
        <ServiceOption
          serviceTitle='Desenvolvimento de Interfaces'
          serviceText='Desenvolvimento de interfaces para seu site ou aplicativo.'
          image={interfaceIcon}
          imageAlt='Iterface icon' />
        <ServiceOption
          serviceTitle='Desenvolvimento de APIs'
          serviceText='Desenvolvimento de APIs para seu site ou aplicativo.'
          image={apiIcon}
          imageAlt='Api icon' />
      </div>
    </StyledServices>
  )
}

export default Services
