import React from 'react'
import StyledServiceOption from './styled'

interface ServiceOptionProps {
  serviceTitle: string,
  serviceText: string,
  image: string,
  imageAlt: string
}

const ServiceOption: React.FunctionComponent<ServiceOptionProps> = ({ image, imageAlt, serviceTitle, serviceText }: ServiceOptionProps) => {
  return (
    <StyledServiceOption>
      <img src={image} className='service-image' alt={imageAlt}/>
      <div className='text-container'>
        <h3 className='service-title'>{serviceTitle}</h3>
        <p className='service-text'>{serviceText}</p>
      </div>
    </StyledServiceOption>
  )
}

export default ServiceOption