import styled from 'styled-components'


const StyledProjectContainer = styled.div`
  width:30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.50rem;

.project-title {
  font-size: 32px;
  margin-bottom: 0.625rem;

}
.project-image {
  width:60%;
  height:400px;
  object-fit: cover;
}
.project-links {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 1.25rem;
  text-decoration: none;
}

.project-link {
  text-decoration: none;
}


@media (max-width: 1024px) {
  width:80%;

    .project-title{
      text-align: center;
    }
    .project-image {
      width:90%;
      height:1000px;
      object-fit: cover;
}
  }

  @media (max-width: 1024px) {
  width:80%;

    .project-image {

      height:400px;

  }
}


`

export default StyledProjectContainer