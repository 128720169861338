import styled from 'styled-components'
import { TitleProps } from './index'

const StyledTitle = styled.h1<TitleProps>`
  box-sizing: border-box;
  font-size: ${(props) => props.fontSize || '45px'} ;
  margin: 0 0.625rem;
  font-family: Quantico;
  text-align: center;
  cursor: pointer;
  span{
    font-family: Quantico;
    color: #D2442D;
  }

  @media (max-width: 1368px) {
    font-size: 36px;
  }

  @media (max-width: 1150px) {
    font-size: 30px;
  }
`

export default StyledTitle
