import styled from 'styled-components'

const StyledApp = styled.div`
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;

`

export default StyledApp
