import React from 'react'
import StyledTitle from './styled'
import { useHistory } from 'react-router-dom'

export interface TitleProps {
    fontSize?: string,
}


const Title = ({ fontSize}: TitleProps) => {

    const history = useHistory()

    const handleClick = () => {
        history.push('/')
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    return(

        <StyledTitle fontSize={fontSize} onClick={handleClick}>
           Eduardo <span>Magno</span>
        </StyledTitle>

    )
}

export default Title
