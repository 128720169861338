import styled from 'styled-components'

const StyledServiceOption = styled.div`
display:flex;
margin: 1.25rem;

.service-image {
  width: 200px;
  height: 152px;
}

.service-title {
  font-size: 25px;
  margin: 1.25rem;
  margin-bottom:2.50rem ;
}

.service-text {
  font-size: 20px;
  width:350px;
  margin: 1.25rem;
}

@media (max-width: 768px) {
  width: 100%;
  flex-direction: column;
  align-items: center;

  .service-title {
  font-size: 23px;
  margin-top: 2.50rem;
  margin-bottom:2.50rem ;
}
.service-text {
  font-size: 18px;
  margin-bottom: 2.50rem;
}

}


`

export default StyledServiceOption