import styled from 'styled-components'

const StyledNavigationMobile = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width:85%;
    height:100%;
    background-color: #FCFCFC;
    box-shadow: 2px 2px 10px #4D4D4D;
    display: flex;
    flex-direction:column;
    font-size: 48px;

    .link-nav-container {
        margin-top:80px;
        display: flex;
        flex-direction: column;
    }
    .nav-back {

        display: flex;
        justify-content: flex-end;

        img {
            margin: 1.25rem;
            width: 30px;
            height: 30px;
        }

    }


    .link-nav-mobile {
        font-size: 22px;
        margin:1.25rem;
        margin-left: 50px;
        text-decoration: none;
        color: #0B1737;
    }
`

export default StyledNavigationMobile