import styled from 'styled-components'

const TextArea = styled.textarea`
border: 2px solid #D2442D;
border-radius: 5px;
width:100%;
background-color: #fff;
outline-style: none;
font-size: 20px;
margin: 1.25rem;
`

export default TextArea