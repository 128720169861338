import React from 'react'
import StyledPageTitle from './styled'

export interface PageTitleProps {
  titleName: string,
  subTitle?: string,
  topMargin?: string | number,
  bottomMargin?: string | number
}

const PageTitle: React.FunctionComponent<PageTitleProps> = ({ titleName, subTitle, topMargin, bottomMargin }: PageTitleProps) => {
  return (
    <StyledPageTitle topMargin={topMargin} bottomMargin={bottomMargin}>
      <h2 className='page-title'> {titleName} </h2>
      <p className={subTitle ? 'page-sub-title' : ''}>{subTitle && subTitle}</p>
    </StyledPageTitle>
  )
}

export default PageTitle