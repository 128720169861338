import styled from 'styled-components'

const Footer = styled.footer`
width: 100%;
height: 40px;
margin-top: 2.50rem;
border-top: 2px solid #D2442D;
display: flex;
justify-content: center;
align-items: center;
`

export default Footer